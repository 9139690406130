"use client";

import PageLoader from "../Loading/PageLoading";

const Home = () => {
  return (
    <>
      <PageLoader />
    </>
  );
};

export default Home;
